<!--
* @Description:
-->
<template>
  <div>
    <el-row class="hedaer-Bg" type="flex">
        <el-col :span="12">
          <div class="grid-content header-left-content">
            <div class="logo-wrap">
              <img src="../assets/image/homeImg/logo.png" />
            </div>
            <div class="app-title" @click="pageJumpLogin">
              河南省团干部网络教育平台
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content header-right-content">
            <div @click="pageJumpLogin" v-if="userName == ''">登录</div>
            <div v-else class="userName-wrap">
              <el-dropdown placement="bottom">
                <span class="el-dropdown-link">
                  学员{{ userName }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="$router.push('/accountSafe')">账号安全</el-dropdown-item>
                  <el-dropdown-item @click.native="pageJumpLogin"
                    >退出登录</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </el-col>
      </el-row>
  </div>
</template>

<script>
export default {
  name: '',
  components: {

  },
  props: {},
  watch: {},
  data () {
    return {
      userName: '',
    }
  },
  methods: {
    // 获取local中用户详细信息
    getUserInfo () {
      let userinfo = localStorage.getItem('studentInfo')
        ? JSON.parse(localStorage.getItem('studentInfo'))
        : ''
      console.log(userinfo)
      if (userinfo) {
        let { stuName } = userinfo
        this.userName = stuName
      }
    },
    pageJumpLogin () {
      this.$router.push('/login')
    },
  },
  mounted () {
    this.getUserInfo()
  }
}
</script>

<style scoped lang="scss">
.hedaer-Bg {
  /* background-color:#6a8dea; */
  background-size: 100% 100%;
  height: 56px;
  width: 1240px;
  margin: 0 auto;
  line-height: 56px;
  /* padding: 0 100px; */

  .header-left-content {
    font-size: 22px;
    color: white;
    font-weight: 800;
    display: flex;
    justify-content: start;

    .app-title {
      cursor: pointer;
    }

    .logo-wrap {
      padding: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 45px;
      }
    }
  }

  .header-right-content {
    font-size: 18px;
    color: white;
    height: 56px;
    display: flex;
    justify-content: end;
    align-items: center;

    .userName-wrap {
      height: 34px;
      line-height: 34px;
      align-items: center;
      display: flex;
      justify-content: center;
    }

    ::v-deep .el-dropdown {
      font-size: 18px;
      color: #4774df;
      background-color: #fff;
      height: 34px;
      line-height: 34px;
      border-radius: 5px 5px 5px 5px;
      opacity: 1;
      padding: 0px 10px;
    }
  }
}
</style>
